import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import heroImg from "../assets/heroimage.png";
import Button, { LearnMore, SecButton } from "../components/buttons";
import valone from "../assets/valone.png";
import valtwo from "../assets/pluskit.png";
import valfour from "../assets/valfour.png";
import valfive from "../assets/valfive.png";
import ourimg from "../assets/ourimg.png";
import evone from "../assets/evone.png";
import evtwo from "../assets/evtwo.png";
import evthree from "../assets/evthree.png";
import circle from "../assets/bgcircle.png";
import { FiArrowRight } from "react-icons/fi";
import TestimonialSlider from "../components/home/TestimonialSlider";
import Events from "../components/home/events";
import FAQ from "../components/home/faq";
import GrowWithUs from "../components/home/GrowWithUs";
import Footer from "../components/home/footer";
import Community from "../components/home/community";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import HeroSlider from "../components/home/HeroSlider";
import WhatWeDo from "../components/home/WhatWeDo";
import OurClients from "../components/our-clients/OurClients";
import valuesimg from "../assets/valuesimg.png";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  const navigate = useNavigate();

  const events = [
    {
      img: evone,
      eventName: "2020 lautech bootcamp",
      desc: "The event was a stepping stone for many to embark on impactful projects, and its success continues to inspire ongoing initiatives within our foundation. Participants engaged in hands-on workshops, interactive sessions, and mentorship opportunities that empowered them to become change agents in their communities.",
      btn: "Learn More",
    },
    {
      img: evtwo,
      eventName: "2019 LAUTECH BOOTCAMP TRAINING",
      desc: "In 2019, CEFCODEV hosted a transformative Bootcamp Training event focused on equipping individuals with practical skills to drive community development. Participants engaged in hands-on workshops, interactive sessions, and mentorship opportunities that empowered them to become change agents in their communities. ",
      btn: "Learn More",
    },
    {
      img: evthree,
      eventName: "2019 BOOTCAMP TRAINING",
      desc: "The 2019 Bootcamp Training proved to be a transformative experience for participants, who engaged in immersive skill-building workshops and interactive sessions. Under the mentorship of industry experts, attendees acquired practical knowledge and established valuable connections, empowering them to become catalysts for positive change within their communities.",
      btn: "Learn More",
    },
  ];

  const value = [
    {
      img: valone,
      title: "Training and Capacity Building",
      desc: "We equip individuals with essential skills for community development through targeted training programs.",
    },
    {
      img: valtwo,
      title: "Disaster Relief and Humanitarian Aid",
      desc: "CEFCODEV provides swift disaster relief and humanitarian support, mobilizing resources to assist communities in crisis.",
    },
    {
      img: valfour,
      title: "Volunteer and Internship Programs",
      desc: "We offer hands-on volunteer and internship opportunities, fostering personal growth and community impact.",
    },
    {
      img: valfive,
      title: "Enterpreneurship and Economic Growth",
      desc: "We support small businesses and startups, driving economic growth and sustainable community development.",
    },
  ];

  return (
    <div className=" overflow-x-hidden">
      <div className="">
        <Navbar />
      </div>
      <div data-aos="zoom-in" className="   ">
        <HeroSlider />
      </div>

      <div className=" border-t mt-[-30px] pt-5">
        <WhatWeDo value={value} />
        <div className=" lg:flex md:grid grid pt-3">
          <div className=" lg:w-[45%]"></div>
          <div className=" lg:w-[55%]">
            <div className="   flex items-center lg:pl-8 md:pl-8 pl-8">
              <Button
                onClick={() => navigate("/focus")}
                text="View all"
                css={
                  "text-[#4CAF50] w-44 h-14 text-lg border border-[#4CAF50] rounded-full"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" mb-16 mt-20 lg:px-24 md:px-10 px-8">
        <div>
          <p className=" text-center  text-[#4CAF50] font-Roboto text-xl font-medium ">
            Meet Our Outstanding Partners
          </p>
          <p className=" text-center text-stroke Satoshi lg:text-5xl md:text-5xl text-3xl text-primary font-black mt-2">
            Our Partners
          </p>
        </div>
        <div className=" justify-center flex items-center lg:my-20 md:my-20 my-10">
          <p className=" text-center text-[#A1A1B0] font-Nunito w-full  lg:max-w-[55%] lg:text-xl md:text-xl text-sm">
            We are proud to work with a diverse group of organizations and
            individuals who share our vision for community empowerment.
          </p>
        </div>
        <OurClients />
      </div>

      {/* <div className=" bg-[#F1F5F9] lg:px-36 md:px-16 px-8 py-8 mt-10 lg:gap-0 md:gap-0 gap-10 lg:flex md:flex grid items-center lg:justify-between md:justify-between justify-center">
        <div
          data-aos="fade-right"
          className=" grid justify-center lg:w-52 md:w-40 w-52 gap-5"
        >
          <p className=" text-center font-Roboto lg:text-6xl md:text-6xl text-5xl font-extrabold text-[#1B1B1B]">
            10+
          </p>
          <p className=" text-center font-Roboto text-2xl font-normal text-[#1B1B1B]">
            States
          </p>
        </div>
        <div
          data-aos="fade-right"
          className=" grid justify-center lg:w-52 md:w-52 w-52 gap-5"
        >
          <p className=" text-center font-Roboto lg:text-6xl md:text-6xl text-5xl font-extrabold text-[#1B1B1B]">
            50+
          </p>
          <p className=" text-center font-Roboto text-2xl font-normal text-[#1B1B1B]">
            Valued Instructors
          </p>
        </div>
        <div
          data-aos="fade-right"
          className=" grid justify-center lg:w-52 md:w-52 w-52 gap-5"
        >
          <p className=" text-center font-Roboto lg:text-6xl md:text-6xl text-5xl font-extrabold text-[#1B1B1B]">
            100K+
          </p>
          <p className=" text-center font-Roboto text-2xl font-normal text-[#1B1B1B]">
            Happy Students
          </p>
        </div>
      </div> */}

      <div className="  pt-20 lg:px-64 md:px-10  px-8">
        <div className=" flex justify-center items-center">
          <p className="text-stroke text-primary font-extrabold lg:text-5xl md:text-4xl text-3xl Satoshi">
            CORE VALUES
          </p>
        </div>

        <div className=" lg:flex md:grid grid justify-center gap-20 mt-10 items-center ">
          <div className=" lg:w-1/2 md:w-full w-full">
            <ul className=" grid gap-5 ">
              <li className="text-[#475569] font-Nunito text-[17px] list-disc">
                Sustainability Development: We prioritize long-term solutions
                that address the root causes of issues and promote resilience.
              </li>
              <li className="text-[#475569] font-Roboto text-[17px] list-disc">
                Community Impact: We believe in the potential of communities to
                lead their own development and prioritize their voices in our
                work.
              </li>
              <li className="text-[#475569] font-Roboto text-[17px] list-disc">
                Development of Africa continent:
              </li>
              <li className="text-[#475569] font-Roboto text-[17px] list-disc">
                Improving the livelihood of people:{" "}
              </li>
            </ul>
          </div>
          <div className=" lg:w-1/2 md:w-full w-full">
            <img data-aos="zoom-in" src={valuesimg} alt="" />
          </div>
        </div>

        <div className=" flex justify-center items-center mt-10">
          <div className=" flex items-center gap-2  bg-[#4CAF50] py-4 px-4 text-white cursor-pointer">
            <p className=" font-semibold text-base">See how it helped others</p>
            <FiArrowRight className=" text-[#fff]" size={24} />
          </div>
        </div>
      </div>

      <div className=" bg-[#000] relative py-16 mt-16">
        <img
          className=" absolute right-2 top-2 w-52 h-52"
          src={circle}
          alt=""
        />

        <div data-aos="fade-down" className=" space-y-2 lg:px-24 md:px-10 px-8">
          <p className=" text-xl font-Roboto text-[#FCD34D] font-medium">
            Testimonials
          </p>
          <p className="text-stroke Satoshi font-extrabold lg:text-5xl md:text-4xl text-3xl text-[#fff]">
            Hear from our beneficiaries
          </p>
          <p className=" text-[#fff] text-lg font-light lg:w-[700px] pt-2">
            Discover the experiences of our beneficiaries and how CEFCODEV has
            helped transform their communities and personal growth.
          </p>
        </div>

        <div className="lg:px-24 md:px-10 px-8 pt-10">
          <TestimonialSlider />
        </div>
      </div>

      <div className=" lg:px-24 md:px-10 px-8 pt-16 pb-10">
        <div className="">
          <p
            data-aos="fade-right"
            className="text-stroke text-[#1B1B1B] Satoshi lg:text-4xl md:text-4xl text-3xl font-extrabold"
          >
            Our Seasoned Events
          </p>
          <p
            data-aos="fade-left"
            className=" pt-5 text-[#1b1b1b] font-light text-xl lg:w-[760px]"
          >
            Whether you're looking to advance your skills, gain insights into
            the latest trends, or simply connect with like-minded individuals,
            we have something for everyone. Check out our recent and upcoming
            events below:
          </p>
        </div>
        <div
          // data-aos="zoom-in"
          className=" pt-5 grid justify-center"
        >
          <Events events={events} />

          <div className="   flex items-center justify-center pt-10">
            <Button
              text="View all"
              css={
                "text-[#1E0E62] w-44 h-14 text-lg border border-[#1E0E62] rounded-full"
              }
            />
          </div>
        </div>
      </div>

      <div className=" lg:px-24 md:px-10 px-8 justify-center grid items-center py-16 bg-[#F1F5F9]">
        <FAQ />
      </div>

      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default Home;

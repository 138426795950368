import React from "react";
import Button, { LearnMore } from "../buttons";
import Sectionimg from "../../assets/Sectionbg.jpeg";

const GrowWithUs = () => {
  return (
    <div>
      <div
        // data-aos="zoom-in"
        className=" bg-cover bg-center lg:flex items-center justify-center rounded-xl"
        style={{ backgroundImage: `url(${Sectionimg})` }}
      >
        <div className="lg:flex w-full items-center lg:justify-end p-16 bg-black bg-opacity-45 rounded-xl">
          <div className=" grid justify-center ">
            <div className=" ">
              <p className="lg:w-[420px] text-stroke text-white font-extrabold lg:text-5xl md:text-5xl text-3xl Satoshi">
                Build and Grow with US!
              </p>
              <p className="lg:w-[470px] text-white lg:text-lg md:text-lg text-sm font-light py-5">
                At CEFCODEV, we believe in empowering individuals and
                communities to create sustainable growth from the ground up. By
                joining hands with us, you become a vital part of our mission to
                foster long-term, community-driven development. Together, we can
                build resilient communities, nurture lasting change, and make a
                global impact. Join us on this transformative journey!
              </p>

              <LearnMore text="Get involved now!" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowWithUs;

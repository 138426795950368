import React from "react";
import { useNavigate } from "react-router-dom";

const Events = ({ events }) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className=" grid lg:grid-cols-3 gap-5 grid-cols-1 md:grid-cols-2 justify-center">
        {events.map((i) => (
          <div
            // data-aos="fade-left"
            className=" border border-[#CCCCCC] rounded-lg lg:w-[342px] md:w-[372px] w-full"
          >
            <div>
              <img src={i.img} alt="" />
            </div>
            <div className=" p-5">
              <div className=" space-y-3">
                <p className=" text-center text-[#1E0E62] font-DMSans text-sm font-extrabold    ">
                  {i.eventName}
                </p>
                <p className=" text-center text-[#A1A1B0] font-extralight text-base font-DMSans">
                  {i.desc}
                </p>
              </div>
              <div className=" justify-center grid items-center pt-5">
                <button
                  onClick={() => navigate("/our-events")}
                  className=" text-white bg-[#4CAF50] rounded-full w-32 h-10"
                >
                  {i.btn}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;

import React, { useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import focushero from "../assets/focushero.png";
import WhatWeDo from "../components/home/WhatWeDo";
import valone from "../assets/valone.png";
import valtwo from "../assets/valtwo.png";
import moreone from "../assets/moreone.png";
import moretwo from "../assets/moretwo.png";
import morethree from "../assets/morethree.png";
import morefour from "../assets/morefour.png";
import morefive from "../assets/morefive.png";
import moresix from "../assets/moresix.png";
import moreseven from "../assets/moreseven.png";
import moreeight from "../assets/moreeight.png";
import morenine from "../assets/morenine.png";
import Aos from "aos";
import "aos/dist/aos.css";
import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";

const Focus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  const value = [
    {
      img: valone,
      title: "Capacity Building and Training",
      desc: "We enhance the skills and knowledge of individuals and organizations, enabling them to tackle challenges effectively and promote sustainable development.",
    },
    {
      img: valtwo,
      title: "Research and Advocacy",
      desc: "Our research efforts inform policies and drive advocacy for community needs, ensuring that local voices are heard in decision-making processes.",
    },
  ];
  const morevalue = [
    {
      img: moreone,
      title: "Economic Growth",
      desc: "We foster economic development by supporting local businesses, creating job opportunities, and promoting entrepreneurship in the community.",
    },
    {
      img: moretwo,
      title: "Community-Based Development Projects",
      desc: "Our projects are designed to address specific community needs, empowering residents to lead their own development initiatives for lasting change.",
    },
    {
      img: morethree,
      title: "Gender Equality and Women's Empowerment",
      desc: "We promote gender equality by empowering both men and women through education, skills training, and leadership opportunities, fostering a more equitable society.",
    },
    {
      img: morefour,
      title: "Volunteer and Internship Programs",
      desc: "Our programs connect individuals with meaningful opportunities to gain experience, contribute to community development, and build valuable networks.",
    },
    {
      img: morefive,
      title: "Youth Development",
      desc: "We focus on empowering youth through skills training, mentorship, and leadership programs, equipping them to be active contributors to their communities.",
    },
    {
      img: moresix,
      title: "Water, Sanitation, and Hygiene",
      desc: "We implement projects to improve access to clean water, sanitation, and hygiene education, promoting public health and well-being in communities.",
    },
    {
      img: moreseven,
      title: "Food and Agriculture",
      desc: "Our initiatives support sustainable agriculture practices, enhance food security, and educate communities on healthy nutrition.",
    },
    {
      img: moreeight,
      title: "Healthcare",
      desc: "We improve healthcare access and awareness through outreach programs, ensuring that communities receive essential medical services and education.",
    },
    {
      img: morenine,
      title: "Disaster Relief and Humanitarian Aid",
      desc: "Our rapid response teams provide vital support during crises, delivering aid and resources to affected communities to help them recover and rebuild.",
    },
  ];
  return (
    <>
      <div className="border-[#E2E8F0] border-b">
        <Navbar />
      </div>
      <div
        data-aos="zoom-in"
        className=" lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center "
      >
        <div className=" lg:w-1/2 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:text-7xl md:text-7xl text-6xl font-semibold">
              Learn more about our areas of Focus
            </p>
            <p className=" text-base text-[#1B1B1B] lg:w-[490px] font-light font-DMSans">
              CEFCODEV plays a vital role in both community and global
              development by addressing social, economic, and environmental
              challenges that may be overlooked or inadequately handled by
              governments, empowering communities, promoting equality, and
              fostering resilience,
            </p>
          </div>
        </div>
        <div className=" lg:w-1/2 md:w-full w-full ">
          <img src={focushero} alt="" />
        </div>
      </div>

      <div className=" pt-20">
        <WhatWeDo value={value} />
      </div>

      <div className=" lg:px-24 md:px-10 px-8 pt-10">
        <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 justify-center">
          {morevalue.map((v) => (
            <div
              data-aos="fade-right"
              className="grid lg:w-[296px] md:w-80 w-full gap-3 "
            >
              <div className=" grid gap-3">
                <img className=" w-8 h-8" src={v.img} alt="" />
                <p className=" font-extrabold text-[21px] Satoshi text-strok tracking-wide text-[#0F172A] ">
                  {v.title}
                </p>
              </div>
              <div className=" ">
                <p className=" font-light ">{v.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:px-24 md:px-10 px-8 pt-20 pb-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </>
  );
};

export default Focus;

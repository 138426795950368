import React, { useEffect } from "react";
import abouthero from "../assets/abouthero.png";
import Navbar from "../components/navbar/Navbar";
import missionimg from "../assets/missionimg.png";
import visionimg from "../assets/visionimg.png";
import userone from "../assets/useone.png";
import usertwo from "../assets/usertwo.png";
import userthree from "../assets/userthree.png";
import userfour from "../assets/userfour.png";
import userfive from "../assets/userfiv.png";
import usersix from "../assets/usersix.png";
import userseven from "../assets/userseven.png";
import usereight from "../assets/usereight.png";
import usernine from "../assets/usernine.png";
import userten from "../assets/userten.png";
import usereleven from "../assets/usereleven.png";
import projone from "../assets/projone.png";
import projtwo from "../assets/projtwo.png";
import projthree from "../assets/projthree.png";
import projfour from "../assets/projfour.png";
import {
  FaDribbble,
  FaFacebookSquare,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import GrowWithUs from "../components/home/GrowWithUs";
import Community from "../components/home/community";
import Footer from "../components/home/footer";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  const members = [
    {
      name: "Johnson Ibironke",
      role: "Executive Director",
      img: userone,
      social: {
        twitter: "#",
        facebook: "#",
        instagram: "#",
      },
    },
    {
      name: "Aderonke Ibironke",
      role: "Finance Manager",
      img: usertwo,
      social: {
        twitter: "#",
        googlePlus: "#",
        instagram: "#",
      },
    },
    {
      name: "Esther Odewunmi",
      role: "Finance Officer",
      img: userthree,
      social: {
        twitter: "#",
        facebook: "#",
      },
    },
    {
      name: "Feranmi Oyeyemi",
      role: "Media/IT Personnel",
      img: userfour,
      social: {
        googlePlus: "#",
        instagram: "#",
      },
    },
    {
      name: "Felix Akindele",
      role: "M & E Officer",
      img: userfive,
      social: {
        twitter: "#",
        instagram: "#",
      },
    },
    {
      name: "Esther Okunola",
      role: "HR/Communication Personnel",
      img: usersix,
      social: {
        twitter: "#",
        instagram: "#",
      },
    },
    {
      name: "Deborah Olajire",
      role: "Programs Manager",
      img: userseven,
      social: {
        twitter: "#",
        instagram: "#",
      },
    },
    {
      name: "Debbie Ayoola",
      role: "Administrative/Office Manager",
      img: usereight,
      social: {
        twitter: "#",
        instagram: "#",
      },
    },
    // {
    //   name: "Della Wintheiser",
    //   role: "Product Manager",
    //   img: usernine,
    //   social: {
    //     twitter: "#",
    //     instagram: "#",
    //   },
    // },
    // {
    //   name: "Della Wintheiser",
    //   role: "Product Manager",
    //   img: userten,
    //   social: {
    //     twitter: "#",
    //     instagram: "#",
    //   },
    // },
    // {
    //   name: "Della Wintheiser",
    //   role: "Product Manager",
    //   img: usereleven,
    //   social: {
    //     twitter: "#",
    //     instagram: "#",
    //   },
    // },
  ];

  const projects = [
    {
      name: "EmpowerHer Initiative",
      description:
        "Empowers women through skill development and entrepreneurship training, fostering economic independence and leadership roles, and promoting gender equality.",
      img: projone,
      impact: "NB: These are fictional projects",
    },
    {
      name: "Green Futures Project",
      description:
        "Empowers women through skill development and entrepreneurship training, fostering economic independence and leadership roles, and promoting gender equality.",
      img: projtwo,
      impact: "NB: These are fictional projects",
    },
    {
      name: "Youth Leadership Academy",
      description:
        "Equips youth with leadership skills, encouraging active citizenship and cultivating the next generation of community leaders.",
      img: projthree,
      impact: "NB: These are fictional projects",
    },
    {
      name: "Health and Wellness Outreach",
      description:
        "Enhances public health awareness and access to healthcare, promoting healthier lifestyles and reducing health disparities in the community.",
      img: projfour,
      impact: "NB: These are fictional projects",
    },
  ];

  return (
    <div className=" overflow-x-hidden">
      <div className="border-[#E2E8F0] border-b">
        <Navbar />
      </div>
      <div
        data-aos="zoom-in"
        className=" lg:pl-24 lg:pr-0 md:px-10 px-8 lg:pt-0 md:pt-10 pt-10 lg:flex md:grid grid items-center lg:gap-6 md:gap-10 gap-10 justify-center "
      >
        <div className=" lg:w-3/5 md:w-full w-full">
          <div className=" space-y-5">
            <p className=" text-[#1B1B1B] lg:text-7xl md:text-7xl text-6xl font-bold">
              Who We Are - Know More About Us
            </p>
            <p className=" text-lg text-[#0F172A] Slight font-extralight lg:w-[490px] ">
              The Cephas Foundation for Community Development (CEFCODEV) is a
              non-profit organization committed to fostering long-term,
              community-led development across the globe.
            </p>
          </div>
        </div>
        <div className=" lg:w-2/5 md:w-full w-full ">
          <img src={abouthero} alt="" />
        </div>
      </div>

      <div className="lg:px-24 md:px-10 px-8 gap-16 lg:flex md:grid grid justify-center items-center pt-5">
        <div data-aos="fade-down-right" className=" lg:w-3/5">
          <p className="text-stroke text-primary font-bold Satoshi lg:text-5xl md:text-4xl text-3xl">
            Our Mission
          </p>
          <p className="Satoshi text-[#000] pt-5 font-light text-xl">
            Our mission is to empower communities to improve their
            socio-economic conditions in a sustainable way. We believe that the
            key to lasting development lies in local leadership, cultural
            understanding, and building the capacity of individuals and
            institutions to drive their own progress. By providing resources,
            training, and support, we enable communities to develop practical
            and innovative solutions that are tailored to their unique contexts.
          </p>
        </div>
        <div data-aos="fade-up-left" className=" lg:w-2/5">
          <img src={missionimg} alt="" />
        </div>
      </div>

      <div
        data-aos="zoom-in"
        className="lg:px-24 md:px-10 px-8 gap-14 pb-5 lg:flex md:grid grid justify-center items-center "
      >
        <div className=" lg:w-[47%]">
          <img src={visionimg} alt="" />
        </div>
        <div className=" lg:w-[53%]">
          <p className="text-stroke text-primary font-bold Satoshi lg:text-5xl md:text-4xl text-3xl">
            Our Vision
          </p>
          <p className="Satoshi text-[#000] pt-5 font-light text-xl">
            We envision a world where all communities have the capacity to
            overcome challenges, improve their quality of life, and protect
            their environment. By promoting sustainable development practices,
            we aim to create a future where poverty, inequality, and
            environmental degradation are eradicated.
          </p>
        </div>
      </div>

      <div className=" bg-[#000] py-16  ">
        <div
          // data-aos="fade-down-right"
          className="lg:px-24 md:px-10 px-5"
        >
          <p className="text-stroke lg:text-4xl md:text-4xl text-3xl font-bold Satoshi text-[#fff]">
            Our Distinguished Members
          </p>
          <p className=" font-Nunito text-xl pt-5 text-[#fff] lg:w-[830px]">
            Lorem ipsum dolor sit amet consectetur. Quis id viverra at quisque
            platea aliquam. Mattis mattis dictum sit bibendum quis habitant.
            Faucibus vitae dui fermentum eu et auctor egestas suspendisse.
            Venenatis vitae ultrices.
          </p>
        </div>
        <div className=" grid justify-center mt-10 lg:px-24 ">
          <div
            // data-aos="zoom-out"
            className="grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-6 justify-center items-center"
          >
            {members.map((member, index) => (
              <div key={index} className="flex flex-col items-center w-44">
                <img
                  src={member.img}
                  alt={member.name}
                  className="w-24 h-24 rounded-full mb-4"
                />
                <div className="text-center">
                  <p className="text-white font-medium">{member.name}</p>
                  <p className="text-white text-sm">{member.role}</p>
                  <div className="flex justify-center space-x-3 mt-2">
                    {member.social.twitter && (
                      <a href={member.social.twitter} className="text-white">
                        <FaTwitter />
                      </a>
                    )}
                    {member.social.facebook && (
                      <a href={member.social.facebook} className="text-white">
                        <FaFacebookSquare />
                      </a>
                    )}
                    {member.social.instagram && (
                      <a href={member.social.instagram} className="text-white">
                        <FaInstagram />
                      </a>
                    )}
                    {member.social.googlePlus && (
                      <a href={member.social.dribbble} className="text-white">
                        <FaDribbble />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className=" pt-16 lg:px-24 md:px-10 px-8 ">
        <div className=" grid justify-center items-center">
          <p
            data-aos="fade-right"
            className=" text-stroke text-center text-main font-extrabold Satoshi lg:text-4xl md:text-4xl text-3xl"
          >
            Our Community Projects
          </p>
        </div>
        <div
          data-aos="fade-left"
          className=" grid justify-center items-center pt-5"
        >
          <p className=" lg:text-xl text-[#A1A1B0] md:text-base text-sm lg:w-[660px] Slight font-light text-center">
            Discover the impactful initiatives that CEFCODEV is driving in our
            communities! Our projects focus on sustainable development,
            education, and empowerment, creating positive change and fostering
            resilience.
          </p>
        </div>

        <div className=" grid justify-center items-center">
          <div
            data-aos="fade-down-right"
            className="grid lg:grid-cols-2 gap-10 pt-10"
          >
            {projects.map((project, index) => (
              <div
                key={index}
                className="bg-white shadow-lg lg:w-[460px] rounded-lg overflow-hidden"
              >
                <img
                  src={project.img}
                  alt={project.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-lg font-bold text-gray-800">
                    {project.name}
                  </h3>
                  <p className="text-[#A1A1B0] text-base font-NunitoSans font-extralight mt-2">
                    {project.description}
                  </p>
                  <p className="text-blue-500 font-semibold mt-4">
                    {project.impact}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="lg:px-24 md:px-10 px-8 py-4 ">
        <GrowWithUs />
      </div>
      <div className=" ">
        <Community />
      </div>

      <div className="lg:px-24 md:px-10 px-8 ">
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
